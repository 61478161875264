import React, { useEffect, useRef, useState } from "react"
import { useMapEvents } from "react-leaflet"
import {
  MapContainer,
  TileLayer,
  Marker,
  Popup,
  FeatureGroup,
  useMap,
} from "react-leaflet"
import { graphql } from "gatsby"
import MarkerClusterGroup from "react-leaflet-markercluster"
import "react-leaflet-markercluster/dist/styles.min.css"
import L, { markerClusterGroup } from "leaflet"
import { Icon } from "leaflet"

import orangePin from "../images/orange-pin-1.png"
import orangePinCluster from "../images/orange-pin.png"
import Layout from "../components/layout"
import { PageContainer, PopupWrapper, Wrapper } from "../components/mapStyles"
import SearchBox from "../components/searchbox"

import { getCenterOfBounds, getBounds, getDistance } from "geolib"

export const query = graphql`
  query {
    allCategories {
      edges {
        node {
          name
          status
          icon
        }
      }
    }
    allMarkers {
      edges {
        node {
          address
          city
          country
          countryCode
          geolocationPlain
          name
          publicURL
          state
          zip
          category {
            name
          }
        }
      }
    }
  }
`

function ChangeView({ center, zoom }) {
  const map = useMap()
  map.setView(center, zoom)
  return null
}

const Map = ({ data, center, zoom }) => {
  const [searchQuery, setSearchQuery] = useState("")
  const [categoryQuery, setCategoryQuery] = useState("")
  const [mapCenter, setMapCenter] = useState([39.381266, -97.922211])
  const [mapZoom, setMapZoom] = useState(5)
  const [showMarkers, setShowMarkers] = useState([])

  const createClusterCustomIcon = function (cluster) {
    return L.divIcon({
      html: `
        <div>
        <img src=${orangePinCluster} />
        <span>${cluster.getChildCount()}</span>
        </div>
      `,
      className: "marker-cluster-custom",
      iconSize: L.point(40, 40, true),
    })
  }

  const reformatMarkers = function (markersQuery) {
    return markersQuery.map(function (markersQuery) {
      const marker = {}
      marker["name"] = markersQuery.node.name
      marker["searchName"] = markersQuery.node.name
        .toLowerCase()
        .replace(/\s\s+/g, " ")
      marker[
        "publicURL"
      ] = `https://venue.kulturecity.org/venues/${markersQuery.node.publicURL}`
      marker["country"] = markersQuery.node.country
      marker["countryCode"] = markersQuery.node.countryCode
      marker["city"] = markersQuery.node.city
        ? markersQuery.node.city.toLowerCase()
        : ""
      marker["state"] = markersQuery.node.state
        ? markersQuery.node.state.toLowerCase()
        : ""
      marker["zip"] = markersQuery.node.zip
      marker["category"] = markersQuery.node.category.name
      marker["geolocationPlain"] = markersQuery.node.geolocationPlain
        ? markersQuery.node.geolocationPlain
        : null
      return marker
    })
  }

  const reformatMarkersCoord = function (markers) {
    const marker = []
    markers
      .filter(marker => marker.geolocationPlain)
      .map(function (markers) {
        marker.push({
          latitude: markers.geolocationPlain[0],
          longitude: markers.geolocationPlain[1],
        })
      })
    return marker
  }

  const reformatCategories = function (categoriesQuery) {
    return categoriesQuery.map(function (categoriesQuery) {
      const category = {}
      if (categoriesQuery.node.status) {
        category["name"] = categoriesQuery.node.name
        category["icon"] = categoriesQuery.node.icon
      }
      return category
    })
  }

  const markers = reformatMarkers(data.allMarkers.edges)
  const categories = reformatCategories(data.allCategories.edges)

  const handleSearch = markers.filter(
    marker =>
      marker.category.includes(categoryQuery) &&
      (marker.searchName.includes(searchQuery) ||
        marker.city.includes(searchQuery) ||
        marker.state.includes(searchQuery))
  )

  const resetMap = () => {
    setSearchQuery("")
    setShowMarkers(markers)
    setMapCenter([39.381266, -97.922211])
    setMapZoom(5)
  }

  useEffect(() => {
    setShowMarkers(handleSearch)
    const coordGroup = handleSearch.length
      ? reformatMarkersCoord(handleSearch)
      : reformatMarkersCoord(markers)

    const newCenter = getCenterOfBounds(coordGroup)

    handleSearch.length &&
      setMapCenter([newCenter.latitude, newCenter.longitude])

    const point1 = {
      latitude: getBounds(coordGroup).maxLat,
      longitude: getBounds(coordGroup).maxLng,
    }
    const point2 = {
      latitude: getBounds(coordGroup).minLat,
      longitude: getBounds(coordGroup).minLng,
    }

    if (getDistance(point1, point2) > 10000000) setMapZoom(3)
    if (getDistance(point1, point2) <= 5000000) setMapZoom(5)
    if (getDistance(point1, point2) <= 100000) setMapZoom(7)
    if (getDistance(point1, point2) <= 50000) setMapZoom(11)
    if (getDistance(point1, point2) <= 20000) setMapZoom(12)
    if (getDistance(point1, point2) <= 10000) setMapZoom(13)
    if (getDistance(point1, point2) === 0) setMapZoom(15)

    searchQuery === "" && categoryQuery === "" && resetMap()
  }, [searchQuery, categoryQuery])

  useEffect(() => {
    resetMap()
  }, [])

  return (
    <Layout>
      <PageContainer>
        <Wrapper>
          {typeof window !== "undefined" && (
            <MapContainer
              center={[39.381266, -97.922211]}
              zoom={5}
              style={{ height: "100vh" }}
              tap={false}
              scrollWheelZoom={false}
            >
              <ChangeView zoom={mapZoom} center={mapCenter} />

              <TileLayer
                attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
                url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
              />
              <MarkerClusterGroup
                showCoverageOnHover={false}
                iconCreateFunction={createClusterCustomIcon}
                maxClusterRadius="50"
              >
                {showMarkers
                  .filter(marker => marker.geolocationPlain && marker.city)
                  .map(marker => (
                    <Marker
                      title={marker.name}
                      position={[
                        marker.geolocationPlain[0],
                        marker.geolocationPlain[1],
                      ]}
                      icon={
                        new Icon({
                          iconUrl: orangePin,
                          iconSize: [27, 35],
                          iconAnchor: [13, 35],
                          popupAnchor: [0, -30],
                        })
                      }
                      key={marker.name}
                    >
                      <Popup>
                        <PopupWrapper>
                          <h2>{marker.name}</h2>
                          <a href={marker.publicURL} target="_blank">
                            More details
                          </a>
                        </PopupWrapper>
                      </Popup>
                    </Marker>
                  ))}
              </MarkerClusterGroup>
            </MapContainer>
          )}
        </Wrapper>
        <SearchBox
          setSearchQuery={setSearchQuery}
          setMapCenter={setMapCenter}
          setMapZoom={setMapZoom}
          setCategoryQuery={setCategoryQuery}
          categories={categories}
          showMarkers={showMarkers}
          searchQuery={searchQuery}
          categoryQuery={categoryQuery}
          resetMap={resetMap}
        />
      </PageContainer>
    </Layout>
  )
}
export default Map
